<template>
  <v-dialog v-model="dialogState" persistent width="95vw" max-width="1200px">
    <v-card>
      <v-toolbar color="accent" fixed>
        <v-toolbar-title>
          <v-icon left>mdi-pencil</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.add_product") }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-title class="headline"></v-card-title>
      <v-card-text>
        <v-form ref="contactEditForm">
          <v-row align="start">
            <v-col cols="12" md="4" align="center">
              <v-row>
                <v-col cols="12">
                  <base-image-input
                    v-model="newItem.img"
                    :defaultImage="newItem.img"
                    v-on:fileToUplaod="fileToUplaod"
                  />
                </v-col>
                <v-col cols="12" class="mt-5">
                  <v-autocomplete
                    :items="countryList"
                    v-model="newItem.target_market"
                    item-text="text"
                    item-value="code"
                    :chips="true"
                    :multiple="true"
                    :deletable-chips="true"
                    :small-chips="true"
                    :label="
                      $vuetify.lang.t('$vuetify.product.target_market') + ' *'
                    "
                    @keyup="validateForm"
                    @change="validateForm"
                    :error="validator.target_market.error_show"
                    :error-messages="validator.target_market.error_msg"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="newItem.barcode_type"
                    :items="
                      $i18n.messages[$i18n.locale]['$vuetify']['product'][
                        'barcode_types'
                      ]
                    "
                    :label="
                      $vuetify.lang.t('$vuetify.product.barcode_type') + ' *'
                    "
                    :error="validator.barcode_type.error_show"
                    :error-messages="validator.barcode_type.error_msg"
                    @change="validateForm"
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  class="mt-2"
                  v-if="
                    newItem.barcode_type == 'itf14' ||
                      newItem.barcode_type == 'ean14' ||
                      newItem.barcode_type == 'sscc18'
                  "
                >
                  <v-row>
                    <v-col cols="4">
                      <v-text-field
                        v-model="newItem.barcode_packaging_level"
                        :label="
                          $vuetify.lang.t(
                            '$vuetify.product.barcode_packaging_level'
                          ) + ' *'
                        "
                        :error="validator.barcode_packaging_level.error_show"
                        :error-messages="
                          validator.barcode_packaging_level.error_msg
                        "
                        type="number"
                        @keyup="validateForm"
                      >
                      </v-text-field>
                      -
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="newItem.barcode_parent"
                        :label="
                          $vuetify.lang.t('$vuetify.product.barcode_parent') +
                            ' *'
                        "
                        :error="validator.barcode_parent.error_show"
                        :error-messages="validator.barcode_parent.error_msg"
                        type="number"
                        @keyup="validateForm"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  v-if="
                    (newItem.barcode_type == 'ean13' ||
                      newItem.barcode_type == 'ean8') &&
                      !limited
                  "
                >
                  <v-text-field
                    v-model="newItem.barcode_root"
                    :label="$vuetify.lang.t('$vuetify.product.existing_code')"
                    type="number"
                    @keyup="validateForm"
                    :error="validator.barcode_root.error_show"
                    :error-messages="validator.barcode_root.error_msg"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" v-if="!limited">
                  <span
                    style="position:absolute; left:0; margin-left:25px;margin-top:-5px;"
                    >{{
                      $vuetify.lang.t("$vuetify.product.barcode_issue_date")
                    }}</span
                  >
                  <div
                    class="v-input v-input--hide-details theme--light v-text-field"
                  >
                    <div class="v-input__control">
                      <div
                        class="v-input__slot"
                        style="width:100%"
                        append-icon="mdi-magnify"
                      >
                        <div class="v-text-field__slot">
                          <datepicker
                            v-model="newItem.issue_date"
                            :language="dateLanguage()"
                            :monday-first="true"
                          ></datepicker>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="8">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newItem.brand.ka"
                    :label="
                      $vuetify.lang.t('$vuetify.product.brand_name_ka') + ' *'
                    "
                    :error="validator.brand.ka.error_show"
                    :error-messages="validator.brand.ka.error_msg"
                    @keyup="validateForm"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newItem.brand.en"
                    :label="
                      $vuetify.lang.t('$vuetify.product.brand_name_en') + ' *'
                    "
                    :error="validator.brand.en.error_show"
                    :error-messages="validator.brand.en.error_msg"
                    v-on:keypress="latinOnly($event)"
                    @keyup="validateForm"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newItem.name.ka"
                    :label="$vuetify.lang.t('$vuetify.product.name_ka') + ' *'"
                    :error="validator.name.ka.error_show"
                    :error-messages="validator.name.ka.error_msg"
                    @keyup="validateForm"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newItem.name.en"
                    :label="$vuetify.lang.t('$vuetify.product.name_en') + ' *'"
                    :error="validator.name.en.error_show"
                    :error-messages="validator.name.en.error_msg"
                    v-on:keypress="latinOnly($event)"
                    @keyup="validateForm"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newItem.type.ka"
                    :label="$vuetify.lang.t('$vuetify.product.type_ka') + ' *'"
                    :error="validator.type.ka.error_show"
                    :error-messages="validator.type.ka.error_msg"
                    @keyup="validateForm"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newItem.type.en"
                    :label="$vuetify.lang.t('$vuetify.product.type_en') + ' *'"
                    :error="validator.type.en.error_show"
                    :error-messages="validator.type.en.error_msg"
                    v-on:keypress="latinOnly($event)"
                    @keyup="validateForm"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newItem.volume_amount"
                    :label="$vuetify.lang.t('$vuetify.product.volume') + ' *'"
                    :error="validator.volume_amount.error_show"
                    :error-messages="validator.volume_amount.error_msg"
                    @keyup="validateForm"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    v-model="newItem.volume_unit"
                    :items="
                      $i18n.messages[$i18n.locale]['$vuetify']['product'][
                        'values'
                      ]
                    "
                    :label="$vuetify.lang.t('$vuetify.product.volume') + ' *'"
                    item-text="value"
                    item-value="key"
                    :error="validator.volume_unit.error_show"
                    :error-messages="validator.volume_unit.error_msg"
                    @change="validateForm"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newItem.package.ka"
                    :label="
                      $vuetify.lang.t('$vuetify.product.item_packaging_ka') +
                        ' *'
                    "
                    :error="validator.package.ka.error_show"
                    :error-messages="validator.package.ka.error_msg"
                    @keyup="validateForm"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newItem.package.en"
                    :label="
                      $vuetify.lang.t('$vuetify.product.item_packaging_en') +
                        ' *'
                    "
                    :error="validator.package.en.error_show"
                    :error-messages="validator.package.en.error_msg"
                    v-on:keypress="latinOnly($event)"
                    @keyup="validateForm"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="gtin.segment"
                    :items="gtins.segment"
                    item-text="text"
                    item-value="code"
                    :error="validator.gtin_segment_code.error_show"
                    :error-messages="validator.gtin_segment_code.error_msg"
                    :label="$vuetify.lang.t('$vuetify.product.segment') + ' *'"
                    @keyup="validateForm"
                    @change="
                      collectGTIN('family', 'segment_code=' + gtin.segment);
                      validateForm()
                    "
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="gtin.family"
                    :items="gtins.family"
                    item-text="text"
                    item-value="code"
                    :label="$vuetify.lang.t('$vuetify.product.family')"
                    @change="
                      collectGTIN(
                        'class',
                        'segment_code=' +
                          gtin.segment +
                          '&family_code=' +
                          gtin.family
                      )
                    "
                    :disabled="!gtin.segment"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="gtin.class"
                    :items="gtins.class"
                    item-text="text"
                    item-value="code"
                    :label="$vuetify.lang.t('$vuetify.product.class')"
                    @change="
                      collectGTIN(
                        'brick',
                        'segment_code=' +
                          gtin.segment +
                          '&family_code=' +
                          gtin.family +
                          '&class_code=' +
                          gtin.class
                      )
                    "
                    :disabled="!gtin.family"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="gtin.brick"
                    :items="gtins.brick"
                    item-text="text"
                    item-value="code"
                    :label="$vuetify.lang.t('$vuetify.product.brick')"
                    @change="
                      collectGTIN(
                        'type',
                        'segment_code=' +
                          gtin.segment +
                          '&family_code=' +
                          gtin.family +
                          '&class_code=' +
                          gtin.class +
                          '&brick_code=' +
                          gtin.brick
                      )
                    "
                    :disabled="!gtin.class"
                  ></v-autocomplete>
                </v-col>
                <v-col v-if="gtins.type" cols="12" md="6">
                  <v-autocomplete
                    v-model="gtin.type"
                    :items="gtins.type"
                    item-text="text"
                    item-value="code"
                    :label="$vuetify.lang.t('$vuetify.product.type')"
                    @change="
                      collectGTIN(
                        'value',
                        'segment_code=' +
                          gtin.segment +
                          '&family_code=' +
                          gtin.family +
                          '&class_code=' +
                          gtin.class +
                          '&brick_code=' +
                          gtin.brick +
                          '&type_code=' +
                          gtin.type
                      )
                    "
                    :disabled="!gtin.brick"
                  ></v-autocomplete>
                </v-col>
                <v-col v-if="gtins.value" cols="12" md="6">
                  <v-autocomplete
                    v-model="gtin.value"
                    :items="gtins.value"
                    item-text="text"
                    item-value="code"
                    :label="$vuetify.lang.t('$vuetify.product.value')"
                    :disabled="!gtin.type"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closePopup">
          <v-icon class="mr-3">mdi-close</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.cancel") }}
        </v-btn>

        <v-btn @click="addNewItem" :disabled="validator.hasError">
          <v-icon class="mr-3">mdi-content-save</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.add_product") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Api from "@/services/api";
import BaseImageInput from "@/components/shared/BaseImageInput";
import ProductHelper from "@/helpers/product";
import Countries from "@/plugins/countries";
import Datepicker from "vuejs-datepicker";
import { en, ge } from "vuejs-datepicker/dist/locale";
export default {
  components: {
    Datepicker,
    BaseImageInput,
  },
  data: function() {
    return {
      limited: this.$route.path == "/customer/products",
      countryList: Countries.forLanguage(this.$i18n.locale),
      dialogState: false,
      newItem: ProductHelper.newItemModel(),
      validator: ProductHelper.validatorModel(),
      gtins: {
        segment: this.$i18n.messages[this.$i18n.locale]["$vuetify"]["segments"],
        family: [],
        class: [],
        brick: [],
        type: false,
        value: false,
      },
      gtin: {
        segment: false,
        family: false,
        class: false,
        brick: false,
        type: false,
        value: false,
      },
    };
  },
  computed: {
    transactionTypes() {
      return ProductHelper.itemTypes();
    },
  },
  watch: {
    popupAddProduct: function(newVal) {
      this.dialogState = newVal;
      this.newItem = ProductHelper.newItemModel();
      this.newItem.barcode_root = false;
      this.newItem.issue_date = new Date().toISOString().slice(0, 10);
    },
    "$i18n.locale": function() {
      this.gtins.segment = this.$i18n.messages[this.$i18n.locale]["$vuetify"][
        "segments"
      ];
    },
  },
  methods: {
    dateLanguage() {
      var dateLocales = new Array();
      dateLocales["en"] = en;
      dateLocales["ka"] = ge;
      return dateLocales[this.$i18n.locale];
    },
    collectGTIN(part, data) {
      Api.gtin(part, data).then((data) => {
        return (this.gtins[part] = data.data.length < 1 ? false : data.data);
      });
    },
    validateForm() {
      this.newItem.gtin_segment_code = this.gtin.segment;
      this.validator = ProductHelper.validateForm(this.newItem);

      if((this.newItem.barcode_type == 'ean13' || this.newItem.barcode_type == 'ean8') && !this.limited) {
        if(this.newItem.barcode_root != '' && this.newItem.barcode_root != null){
          if(this.newItem.barcode_type == 'ean8' && this.newItem.barcode_root.length != 8) {
            this.validator.hasError = true;
            this.validator.barcode_root.error_show = true;
            this.validator.barcode_root.error_msg = this.$vuetify.lang.t("$vuetify.errors.required");
            return false;
          }
          if(this.newItem.barcode_type == 'ean13' && this.newItem.barcode_root.length != 13) {
            this.validator.hasError = true;
            this.validator.barcode_root.error_show = true;
            this.validator.barcode_root.error_msg = this.$vuetify.lang.t("$vuetify.errors.required");
            return false;
          }
          if(!this.eanCheckDigit(this.newItem.barcode_root)) {
            this.validator.hasError = true;
            this.validator.barcode_root.error_show = true;
            this.validator.barcode_root.error_msg = this.$vuetify.lang.t("$vuetify.errors.invalid_check_digit");
            return false;
          }
        }
      }

      if((this.newItem.barcode_type == 'ean14' || this.newItem.barcode_type == 'itf14') && !this.limited) {
          if(this.newItem.barcode_parent.length != 13) {
            this.validator.hasError = true;
            this.validator.barcode_parent.error_show = true;
            this.validator.barcode_parent.error_msg = this.$vuetify.lang.t("$vuetify.errors.required");
            return false;
          }
          if(!this.eanCheckDigit(this.newItem.barcode_parent)) {
            this.validator.hasError = true;
            this.validator.barcode_parent.error_show = true;
            this.validator.barcode_parent.error_msg = this.$vuetify.lang.t("$vuetify.errors.invalid_check_digit");
            return false;
          }
      }
    },
    eanCheckDigit(s){
        let result = 0;
        let i = 1;
        let lastone = s.toString().split('').pop();
        let withoutCheckDigit = s.slice(0, -1)
        for (let counter = withoutCheckDigit.length-1; counter >=0; counter--){
            result = result + parseInt(withoutCheckDigit.charAt(counter)) * (1+(2*(i % 2)));
            i++;
        }
        let checkDigit = (10 - (result % 10)) % 10;
        return checkDigit == lastone
    },
    closePopup() {
      this.dialogState = false;
      this.$emit("closePopupAddProduct");
      this.validator = ProductHelper.validatorModel();
      this.newItem = ProductHelper.newItem;
    },
    fileToUplaod(img) {
      this.newItem.img = img;
    },
    addNewItem() {
      var item = {
        company_id:
          this.$route.params.id ?? JSON.parse(localStorage.user).company_id,
        target_market: this.newItem.target_market,
        image: this.newItem.img,
        brand: {
          en: this.newItem.brand.en,
          ka: this.newItem.brand.ka,
        },
        name: {
          en: this.newItem.name.en,
          ka: this.newItem.name.ka,
        },
        type: {
          en: this.newItem.type.en,
          ka: this.newItem.type.ka,
        },
        barcode_type: this.newItem.barcode_type,
        barcode_packaging_level: this.newItem.barcode_packaging_level,
        barcode_parent: this.newItem.barcode_parent,
        package: this.newItem.package,
        status: this.$route.params.id ? "confirmed" : "review",
        volume_amount: this.newItem.volume_amount,
        volume_unit: this.newItem.volume_unit,
        gtin_segment_code: this.gtin.segment ? this.gtin.segment : null,
        gtin_family_code: this.gtin.family ? this.gtin.family : null,
        gtin_class_code: this.gtin.class ? this.gtin.class : null,
        gtin_brick_code: this.gtin.brick ? this.gtin.brick : null,
        gtin_value_code: this.gtin.value ? this.gtin.value : null,
        gtin_type_code: this.gtin.type ? this.gtin.type : null,
        barcode_root: this.newItem.barcode_root
          ? this.newItem.barcode_root
          : null,
        issue_date: this.newItem.issue_date
      };

      if (item.barcode_root == null) {
        delete item.barcode_root;
      }

      Api.product("create", item).then(() => {
        this.newItem = ProductHelper.newItem;
        this.$emit("editTotalProducts");
        this.$toast.ok(this.$vuetify.lang.t("$vuetify.action.create_success"));
        this.closePopup();
      });
    },
  },
  props: ["popupAddProduct"],
};
</script>

<template>
  <div>
    <v-row>
      <v-col cols="12">
        <Table
          :servicePath="'product/list'"
          :headers="companyHeaders"
          :title="title"
          :sortByKey="sortBy"
          :sortDesc="sortDesc"
          :fieldClickable="fieldClickable"
          icon="mdi-cart"
          tableType="product"
          :showAddProduct="showAddProduct"
          v-on:add-product="dialogAddProduct = true"
          :reload="reload"
          :companyName="companyName"
        />
      </v-col>
    </v-row>

    <AddProduct
      :popupAddProduct="dialogAddProduct"
      v-on:closePopupAddProduct="dialogAddProduct = false"
      v-on:editTotalProducts="reloadTable"
    />
  </div>
</template>

<script>
import Api from "@/services/api";
import Table from "@/components/shared/Table";
import AddProduct from "@/components/shared/popup/AddProduct";
export default {
  components: {
    Table,
    AddProduct,
  },
  data: function() {
    return {
      dialogAddProduct: false,
      sortBy: ["issue_date"],
      sortDesc: [true],
      showAddProduct: this.$route.params.id ?? false,
      reload: 0,
      companyName: false,
      title: this.$vuetify.lang.t("$vuetify.cards.products"),
      fieldClickable: {
        field: "org_name",
        to: "/manager/company/list",
        key: "company_id",
      },
    };
  },
  watch: {
    $route() {
      this.showAddProduct = this.$route.params.id ?? false
    },
  },
  methods: {
    reloadTable() {
      this.reload += 1;
    }
  },
  computed: {
    companyHeaders() {
      var hide = this.$route.params.id ? ' d-none' : '';
      return [
        {
          text: "",
          value: "selectable",
          selectable: true,
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.product.barcode"),
          value: "barcode",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.tables.type"),
          value: "barcode_type",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.tables.company"),
          value: "company.name",
          align: hide
        },
        {
          text: this.$vuetify.lang.t("$vuetify.product.brand_name"),
          value: "brand",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.product.name"),
          value: "name",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.product.type"),
          value: "type",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.product.volume"),
          value: "volume_amount",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.product.value"),
          value: "volume_unit",
          align: " d-none",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.product.item_package"),
          value: "package",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.product.barcode_issue_date"),
          value: "issue_date"
        },
        {
          text: "",
          value: "editProduct",
          sortable: false,
        },
        {
          text: "",
          value: "removeProduct",
          sortable: false,
        },
      ];
    },
  },
  beforeCreate() {
    if(this.$route.params.id) {
      Api.company("get", this.$route.params.id).then(({ data }) => {
        this.companyName = data.name[this.$i18n.locale]
      });
    }
  }
};
</script>
